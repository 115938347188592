export const DELIVERY_STATUS_0 = "DELIVERY_STATUS_RECEIPT";
export const DELIVERY_STATUS_1 = "DELIVERY_STATUS_PICKUP_SCHEDULED";
export const DELIVERY_STATUS_2 = "DELIVERY_STATUS_PICKUP_COMPLETED";
export const DELIVERY_STATUS_3 = "DELIVERY_STATUS_WAREHOUSED";
export const DELIVERY_STATUS_4 = "DELIVERY_STATUS_DELIVERY_STARTED";
export const DELIVERY_STATUS_5 = "DELIVERY_STATUS_DELIVERED";
export const DELIVERY_STATUS_6 = "DELIVERY_STATUS_DELIVERY_SENT_BACK";
export const DELIVERY_STATUS_7 = "DELIVERY_STATUS_DELIVERY_LOST";
export const DELIVERY_STATUS_8 = "DELIVERY_RIDER_ALLOCATED";

export class Delivery {
  allocationGroup = null;
  allocationGroupName = "";
  bookId = "";
  receiverAddress = null;
  receiverAddressRoad = null;
  receiverDongName = "";
  receiverName = "";
  senderName = "";
  spotName = "";
  status = 0;

  constructor(delivery) {
    this.allocationGroup = delivery.allocationGroup;
    this.allocationGroupName = delivery.allocationGroupName;
    this.bookId = delivery.bookId;
    this.receiverAddress = delivery.receiverAddress;
    this.receiverAddressRoad = delivery.receiverAddressRoad;
    this.receiverDongName = delivery.receiverDongName;
    this.receiverName = delivery.receiverName;
    this.senderName = delivery.senderName;
    this.spotName = delivery.spotName;
    this.status = delivery.status;
  }
}
