import React, { useCallback } from "react";
import styled from "@emotion/styled";

import OriginForm from "react-bootstrap/Form";
import OriginButton from "react-bootstrap/Button";

import { useForm } from "react-hook-form";

import { useDeliveryStore } from "../stores/hooks";

const BodyContent = styled.div`
  overflow: hidden;
  position: relative;
  min-height: 100vh;
`

const Container = styled.div`
  width: 936px;
  padding-top: 38px;
  padding-left: 15px;
  padding-right: 15px;
  margin: 0 auto;
`

const Title = styled.h1`
  text-align: center;
  font-weight: 700;
  font-size: 38px;
  line-height: 48px;
  margin-bottom: 50px;
`

const Form = styled(OriginForm)`
  display: flex;
  margin-bottom: 32px;
`
const FormGroup = styled(OriginForm.Group)`
  width: 696px;
  margin-right: 30px;
  margin-bottom: 0;
`

const FormControl = styled(OriginForm.Control)`
  height: 74px;
  border-radius: 12px;
  background-color: #F2F4F8;
  border: none;
  font-size: 28px;
  padding: 0 24px;
  width: 100%;

  &:focus {
    background-color: #F2F4F8;
  }
`

const Button = styled(OriginButton)`
  flex: 1;
  background-color: #323232;
  border: none;
  font-size: 28px;
  border-radius: 12px;

  &:hover {
    background-color: #323232;
    opacity: 0.8;
  }
`

const ErrorText = styled.p`
  margin-top: 2px;
  margin-bottom: 0;
  color: red;
  font-size: 12px;
`

const ResultContainer = styled.div`
  text-align: center;
`

const Message = styled.div`
  font-size: 34px;
  line-height: 26px;
  color: #FF0000;
  margin-bottom: 56px;
`

const Names = styled.div`
  font-weight: 700;
  font-size: 48px;
  line-height: 26px;
  margin-bottom: 56px;
`

const ResultBox = styled.div`
  min-height: 308px;
  background-color: #F2F4F8;
  border-radius: 12px;
  padding: 20px;
`
const Address = styled.div`
  font-weight: 700;
  font-size: 56px;
  line-height: 70px;
  min-height: 137px;
  margin-bottom: 30px;
`
const Code = styled.div`
  font-weight: 700;
  font-size: 74px;
  line-height: 93px;
`

function Main() {
  const { bookId, delivery, errorMessage } = useDeliveryStore().state;
  const { ...actions } = useDeliveryStore();

  const { errors, handleSubmit, register, reset } = useForm();

  const onSubmit = useCallback(({ bookId }) => {
    actions.fetchById(bookId);
    reset({ bookId: '' })
  }, [actions.fetchById]);

  return (
    <BodyContent>
      <Container>
        <Title>두발히어로 지역분류 조회</Title>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <FormGroup>
            <FormControl
              name="bookId"
              placeholder="번호를 입력하세요"
              ref={register({ required: true })}
            />
            <ErrorText>{errors.bookId && "배송번호를 입력해 주세요."}</ErrorText>
          </FormGroup>
          <Button type="submit">
            조회
          </Button>
        </Form>
        {bookId && (
          <ResultContainer>
            <Message>{bookId} 조회 {errorMessage ? `실패(${errorMessage})` : '성공'}</Message>
            {delivery && (
              <>
                <Names>[{delivery.spotName}] [{delivery.senderName}] [{delivery.receiverName}]</Names>
                <ResultBox>
                  <Address>{delivery.receiverAddress} / {delivery.receiverAddressRoad}</Address>
                  <Code>{delivery.allocationGroup || '-'} / {delivery.allocationGroupName}</Code>
                </ResultBox>
              </>
            )}
          </ResultContainer>
        )}
      </Container>
    </BodyContent>
  )
};

export default Main;
