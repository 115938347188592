import React, { createContext, useCallback, useContext, useReducer } from "react";

import { Delivery } from "../../Models/Delivery";

import api from "../Api";

export const CONSTANTS = {
  FETCH_BY_ID: "FETCH_BY_ID",
  SET_ERROR: "SET_ERROR",
};

const INITIAL_STATE = {
  bookId: null,
  delivery: {},
  errorMessage: null
};

const reducer = (state, action) => {
  switch (action.type) {
    case CONSTANTS.FETCH_BY_ID:      
      return {
        ...state,
        bookId: action.bookId,
        delivery: action.delivery,
        errorMessage: null,
      };
    case CONSTANTS.SET_ERROR:      
      return {
        ...state,
        bookId: action.bookId,
        delivery: {},
        errorMessage: action.errorMessage,
      };
    default:
      return INITIAL_STATE;
  }
};

export const Context = createContext(INITIAL_STATE);

export const Provider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

  return (
    <Context.Provider value={{ dispatch, state }}>{children}</Context.Provider>
  );
};

export const useDeliveryStore = () => {
  const { dispatch, state } = useContext(Context);

  const fetchById = useCallback(async (bookId) => {
    try {
      const delivery = await api.get(`/public/deliveries/${bookId}`);

      dispatch({ 
        type: CONSTANTS.FETCH_BY_ID, 
        bookId,
        delivery: new Delivery(delivery),
      });

    } catch(e) {
      dispatch({ 
        type: CONSTANTS.SET_ERROR, 
        bookId,
        errorMessage: e.message || '배송조회에 실패했습니다.',
      });
    }
  }, [api.get, dispatch]);

  return {
    state,
    fetchById,
  };
};
