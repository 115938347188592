import React from 'react';
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import Main from "./pages/index";

import "bootstrap/dist/css/bootstrap.min.css";

import { DeliveryStore } from './stores';

const AppProvider = ({ contexts, children }) =>
  contexts.reduce(
    (prev, context) =>
      React.createElement(context, {
        children: prev,
      }),
    children,
  );

function App() {
  return (
    <AppProvider
      contexts={[DeliveryStore.Provider]}
    >
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="*" element={<Navigate replace to="/" />} />
        </Routes>
      </BrowserRouter>
    </AppProvider>
);
}

export default App;
